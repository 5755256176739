import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoDatabase16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M7.333 1.254c-.824.036-1.541.134-2.226.303a5.754 5.754 0 0 0-1.307.471c-.699.349-1.069.732-1.199 1.238-.036.139-.041.713-.041 4.734 0 4.982-.008 4.71.147 5.027.185.378.623.741 1.226 1.018.708.325 1.637.55 2.747.665.633.066 2.007.066 2.64 0 1.31-.136 2.42-.441 3.133-.862.365-.215.709-.551.841-.821.155-.319.147-.04.14-5.096l-.007-4.638-.061-.16c-.311-.818-1.476-1.442-3.239-1.733-.779-.128-1.879-.186-2.794-.146m1.256 1.199c1.423.064 2.617.339 3.291.759.183.115.36.279.36.335 0 .056-.178.22-.367.338-.868.541-2.699.852-4.48.76a9.784 9.784 0 0 1-1.633-.192c-.7-.14-1.313-.356-1.667-.587-.167-.108-.333-.268-.333-.319 0-.056.177-.22.36-.335.656-.408 1.891-.7 3.213-.758a9.57 9.57 0 0 1 1.256-.001M3.976 5.157c.701.322 1.643.541 2.851.661.433.043 1.913.043 2.346 0 1.208-.12 2.15-.339 2.851-.661.108-.05.201-.09.206-.09.006 0 .01.317.01.705v.706l-.08.091c-.111.127-.382.298-.662.419-.987.425-2.577.644-4.105.564A9.972 9.972 0 0 1 5.76 7.36c-.906-.182-1.657-.491-1.92-.791l-.08-.091v-.706c0-.388.004-.705.01-.705.005 0 .098.04.206.09m0 2.907c.658.303 1.623.534 2.704.646.633.066 2.007.066 2.64 0 1.081-.112 2.046-.343 2.704-.646.108-.05.201-.091.206-.091.006 0 .01.303.01.672v.672l-.143.138c-.414.402-1.53.747-2.897.897-.407.044-1.993.044-2.4 0-1.372-.15-2.481-.494-2.9-.9l-.14-.135v-.672c0-.369.004-.672.01-.672.005 0 .098.041.206.091m.557 3.047c.601.198 1.29.339 2.08.427.586.065 2.188.065 2.774 0 1.032-.115 1.916-.327 2.606-.625l.247-.107v1.684l-.128.122c-.682.647-2.812 1.06-4.832.937-1.573-.096-2.878-.453-3.377-.923l-.143-.135v-1.685l.247.107c.135.058.372.148.526.198"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoDatabase16;
