import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoWarning40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M19.433 3.406c-.473.069-1.219.421-1.619.764-.157.134-.4.414-.539.621-.534.792-14.008 23.74-14.14 24.082-.176.455-.244 1.313-.142 1.789a3.37 3.37 0 0 0 2.445 2.539c.507.13.776.132 14.556.131 12.143-.001 14.096-.014 14.457-.099 1.242-.293 2.19-1.227 2.516-2.48.189-.722.092-1.511-.272-2.229-.324-.641-13.843-23.557-14.098-23.898-.693-.929-1.917-1.401-3.164-1.22M26.927 18.4l6.809 11.567-13.712.016c-7.542.01-13.723.006-13.737-.007-.013-.013 3.059-5.261 6.828-11.662 5.153-8.752 6.87-11.618 6.927-11.559.041.043 3.139 5.283 6.885 11.645m-7.424-3.285c-.336.086-.789.474-.992.852-.137.253-.145.366-.165 2.233-.014 1.386.003 2.051.06 2.253.112.403.401.773.775.993.274.161.389.187.819.186.559-.001.79-.093 1.153-.456.466-.466.478-.53.502-2.66.023-2.147-.013-2.41-.398-2.875-.379-.457-1.133-.683-1.754-.526m-.07 7.908c-.437.144-.658.284-.944.594-.323.35-.556.93-.556 1.383 0 1.082.985 2.067 2.067 2.067 1.082 0 2.067-.985 2.067-2.067 0-.754-.535-1.574-1.219-1.869-.421-.18-1.051-.229-1.415-.108"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoWarning40;
