import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoUsers24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M7.64 4.428c-.738.128-1.53.591-2.047 1.199-.797.935-1.012 2.297-.55 3.493.323.839 1.111 1.617 1.959 1.935 1.452.545 3.074.084 3.991-1.133a3.431 3.431 0 0 0-.014-4.202 3.489 3.489 0 0 0-1.996-1.258c-.279-.058-1.087-.078-1.343-.034m7.351.036c-.342.074-.83.279-1.135.477-.365.238-.888.79-1.092 1.152-.374.664-.539 1.442-.449 2.12.307 2.303 2.568 3.655 4.725 2.824.595-.229 1.234-.756 1.615-1.331.523-.791.683-1.911.407-2.845a3.455 3.455 0 0 0-2.446-2.363c-.454-.115-1.175-.13-1.625-.034M8.732 5.94c.354.092.623.248.892.516.383.384.576.847.576 1.384 0 1.479-1.574 2.413-2.887 1.713-.299-.159-.668-.552-.829-.88-.436-.894-.072-2.045.805-2.547a2.054 2.054 0 0 1 1.443-.186m7.505.002c.734.173 1.347.857 1.44 1.608.081.661-.098 1.198-.554 1.654a2.362 2.362 0 0 1-.473.371 1.965 1.965 0 0 1-2.468-.576 1.833 1.833 0 0 1-.371-1.007c-.043-.61.141-1.111.565-1.535.5-.501 1.155-.682 1.861-.515M7.52 11.924a5.67 5.67 0 0 0-2.646 1.087c-.166.124-.49.422-.72.66-.999 1.034-1.644 2.388-1.936 4.061-.078.451-.049.7.124 1.055.134.276.51.62.807.74l.231.093 4.679.011c3.139.007 4.745-.003 4.88-.031.699-.145 1.21-.706 1.251-1.373.05-.816-.546-2.623-1.189-3.603-1.27-1.936-3.371-2.971-5.481-2.7m7.52.012c-.599.093-1.3.313-1.502.472-.242.191-.326.622-.178.911.203.399.564.489 1.146.288a4.02 4.02 0 0 1 2.526-.024c1.239.416 2.261 1.443 2.828 2.84.168.415.38 1.159.435 1.527l.026.17H18.11c-1.397 0-2.265.016-2.359.042a.779.779 0 0 0-.476.416c-.195.431.046.901.525 1.021.127.032.858.041 2.48.031 2.453-.016 2.391-.011 2.784-.219.238-.126.555-.491.66-.761.161-.415.102-.99-.209-2.042-.622-2.103-2.021-3.712-3.819-4.392-.749-.283-1.874-.402-2.656-.28M9.3 13.537c1.373.412 2.504 1.595 3.063 3.204.129.372.316 1.132.317 1.289v.09h-4.5c-3.574 0-4.5-.01-4.5-.05.001-.107.206-.946.305-1.25a7.079 7.079 0 0 1 .692-1.433c.247-.376.843-1.003 1.191-1.251.483-.345 1.074-.602 1.598-.696.107-.019.482-.03.834-.023.558.011.686.026 1 .12"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoUsers24;
