import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoSsnCard24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M4.852 5.24c-.287.042-.554.18-.802.413a1.515 1.515 0 0 0-.452.728c-.087.299-.087 10.94 0 11.239a1.62 1.62 0 0 0 1.237 1.139c.297.056 14.033.056 14.33 0a1.62 1.62 0 0 0 1.237-1.139c.087-.299.087-10.94 0-11.239a1.515 1.515 0 0 0-.452-.728 1.451 1.451 0 0 0-.838-.415c-.304-.044-13.959-.042-14.26.002M18.94 12v5.26H5.06l-.01-5.22c-.006-2.871-.002-5.243.009-5.27.015-.04 1.426-.048 6.95-.04l6.931.01V12M7.26 9.475c-.332.206-.466.57-.338.912.064.169.316.409.477.453.192.054 4.014.053 4.206-.001.192-.053.461-.345.497-.538a.777.777 0 0 0-.357-.824c-.119-.073-.228-.077-2.245-.077-1.999 0-2.127.005-2.24.075m6.639.022a.872.872 0 0 0-.26.27l-.099.173v3.28l.11.187c.06.102.177.228.26.28.147.091.172.093 1.348.105 1.027.011 1.223.003 1.368-.052.207-.079.441-.33.474-.508.013-.072.019-.862.012-1.755L17.1 9.854l-.115-.151c-.235-.307-.209-.303-1.645-.302h-1.28l-.161.096M15.6 11.58v.7h-.56v-1.4h.56v.7m-8.313.386c-.474.234-.539.927-.118 1.249.216.164.487.193 1.668.178L9.9 13.38l.187-.11a.745.745 0 0 0 .358-.853c-.048-.177-.28-.428-.458-.495-.07-.026-.567-.042-1.32-.042-1.153 0-1.215.004-1.38.086"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoSsnCard24;
