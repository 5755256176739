import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoTrash24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path d="M11.313 3.062a4.333 4.333 0 0 0-3.168 2.327L8 5.678l-2.253.011-2.253.011-.141.096c-.378.26-.462.753-.187 1.103.176.224.356.274 1.044.293.618.017.63.019.63.106 0 .049.189 3.017.42 6.597.293 4.536.437 6.548.475 6.637a.876.876 0 0 0 .414.414c.191.081 11.511.081 11.702 0a.876.876 0 0 0 .414-.414c.038-.089.182-2.101.475-6.637.231-3.58.42-6.548.42-6.597 0-.087.012-.089.63-.106.547-.015.652-.028.796-.103.521-.271.553-.955.061-1.293l-.141-.096-2.253-.011L16 5.678l-.145-.289a4.237 4.237 0 0 0-1.274-1.52 4.307 4.307 0 0 0-3.268-.807m1.382 1.537c.493.126.914.368 1.285.74l.32.321-1.15.011c-.632.005-1.667.005-2.3 0L9.7 5.66l.32-.321a2.754 2.754 0 0 1 1.269-.738c.4-.105 1-.106 1.406-.002M17.64 7.35c.001.23-.762 12.063-.781 12.12-.024.073-9.694.073-9.718 0-.019-.057-.782-11.89-.781-12.12V7.2h11.28v.15m-7.98 2.873a.662.662 0 0 0-.345.357c-.069.15-.073.322-.073 2.76 0 2.454.004 2.609.075 2.765.246.543 1.024.571 1.325.047l.098-.172V10.7l-.106-.18a.696.696 0 0 0-.639-.36.9.9 0 0 0-.335.063m3.959.032a.914.914 0 0 0-.26.272l-.099.173v5.28l.098.172c.301.524 1.079.496 1.325-.047.071-.156.075-.311.075-2.765 0-2.923.012-2.806-.299-3.044-.124-.094-.197-.116-.415-.127-.224-.011-.288.002-.425.086" />
    </svg>
  );
};
export default IcoTrash24;
