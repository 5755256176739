import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoCamera40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M15.933 5.106c-1.147.305-1.492.642-3.072 3.005L11.597 10l-2.682.004c-3.003.004-3.222.03-3.944.464-.465.28-1.048.892-1.273 1.337-.381.752-.365.261-.365 10.695 0 10.468-.018 9.94.376 10.717.227.448.959 1.181 1.407 1.407.778.395-.052.374 14.884.374s14.106.021 14.884-.374c.448-.226 1.18-.959 1.407-1.406.389-.767.374-.337.374-10.718 0-10.346.013-9.954-.363-10.696-.225-.444-.808-1.056-1.273-1.336-.722-.434-.941-.46-3.944-.464L28.403 10l-1.264-1.889c-1.591-2.379-1.93-2.708-3.103-3.009-.574-.147-7.546-.144-8.103.004m8.593 5.111c.694 1.035 1.392 2.009 1.551 2.162.159.154.465.385.679.513.682.408.976.441 3.94.441h2.637v18.334H6.667V13.333h2.654c4.124 0 4.043.042 6.156-3.116l1.259-1.884h6.528l1.262 1.884m-5.595 4.885c-3.668.572-6.204 4.203-5.465 7.827.462 2.266 1.942 4.059 4.055 4.911a6.644 6.644 0 0 0 8.652-3.694 6.602 6.602 0 0 0 .002-4.952 6.63 6.63 0 0 0-5.167-4.097 6.189 6.189 0 0 0-2.077.005m1.941 3.354c1.232.321 2.211 1.417 2.406 2.694.167 1.089-.156 2.071-.94 2.857-.49.492-.932.746-1.553.892-1.116.264-2.199-.019-3.029-.79-2.217-2.061-.775-5.768 2.244-5.768.238 0 .631.052.872.115"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCamera40;
