import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoDollar40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M18.467 3.408c-2.622.231-5.329 1.176-7.567 2.641-1.059.693-1.735 1.247-2.702 2.215-3.277 3.28-4.999 7.643-4.843 12.269.119 3.531 1.231 6.681 3.367 9.534.662.883 2.119 2.374 2.945 3.011 2.403 1.857 4.978 2.961 8 3.433 1.077.168 3.589.168 4.666 0 3.731-.582 6.84-2.144 9.437-4.741 2.597-2.598 4.163-5.714 4.74-9.437.167-1.076.167-3.59 0-4.666-.468-3.016-1.578-5.603-3.432-8-.562-.726-2.014-2.177-2.745-2.743-3.444-2.666-7.589-3.895-11.866-3.516m2.757 3.327a13.204 13.204 0 0 1 8.033 3.691 13.3 13.3 0 0 1 2.715 15.406 13.036 13.036 0 0 1-2.812 3.836 12.513 12.513 0 0 1-3.374 2.333 13.246 13.246 0 0 1-11.572 0c-1.752-.846-3.194-1.976-4.469-3.501-3.331-3.982-4.012-9.663-1.718-14.333 2.243-4.566 6.751-7.419 11.84-7.491.348-.005.959.022 1.357.059m-1.891 2.571c-.335.145-.659.476-.841.861-.131.278-.157.439-.158.976l-.001.642-.25.047c-.457.085-1.247.429-1.76.766-.998.655-1.75 1.658-2.027 2.702-.172.648-.144 1.685.063 2.333.393 1.236 1.283 2.25 2.518 2.872.244.123 1.351.586 2.459 1.029 1.108.443 2.156.878 2.329.965.353.179.712.552.776.806.17.677-.41 1.338-1.408 1.604-.704.189-1.884.071-2.518-.251-.155-.078-.502-.364-.771-.633-.407-.409-.551-.509-.861-.599-1.393-.405-2.599.972-1.993 2.275.403.865 1.761 1.891 3.043 2.3l.367.117.035.758c.028.6.065.813.177 1.024.314.587.846.913 1.488.913.675 0 1.207-.345 1.508-.98.131-.278.157-.439.158-.977l.001-.643.481-.146a5.617 5.617 0 0 0 2.503-1.566c1.232-1.345 1.515-3.254.715-4.835-.423-.836-1.258-1.657-2.164-2.127-.276-.143-1.492-.655-2.702-1.138-2.207-.88-2.532-1.049-2.817-1.467-.197-.29-.204-.797-.014-1.104.308-.499 1.148-.83 2.108-.83 1.123 0 1.756.247 2.467.962.579.583.943.745 1.535.684 1.1-.113 1.803-1.311 1.353-2.304-.393-.866-1.666-1.853-2.98-2.311l-.452-.158-.035-.753c-.028-.595-.065-.809-.177-1.02-.184-.343-.522-.666-.836-.797-.314-.131-1.013-.129-1.319.003"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoDollar40;
