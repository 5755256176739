import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoTrash16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path d="M7.68 1.229a3.408 3.408 0 0 0-1.524.547 3.45 3.45 0 0 0-1.064 1.192l-.118.23-1.654.008c-1.824.008-1.724-.001-1.916.177a.618.618 0 0 0 .149 1.002c.131.065.158.068.586.068h.448v.067c0 .111.61 9.591.625 9.713a.662.662 0 0 0 .361.504c.09.048.25.05 4.427.05s4.337-.002 4.427-.05a.664.664 0 0 0 .361-.504c.016-.128.625-9.588.625-9.704v-.076h.448c.428 0 .455-.003.586-.068a.618.618 0 0 0 .149-1.002c-.192-.178-.092-.169-1.917-.177l-1.655-.008-.088-.179a3.323 3.323 0 0 0-2.331-1.75 3.704 3.704 0 0 0-.925-.04m.857 1.307c.341.095.714.317.955.568l.092.096H6.416l.092-.096c.31-.324.779-.563 1.225-.626.21-.029.586-.002.804.058m3.623 1.951c0 .083-.562 8.795-.575 8.92l-.016.14H4.431l-.016-.14c-.013-.125-.575-8.837-.575-8.92 0-.027.85-.034 4.16-.034s4.16.007 4.16.034M6.373 6.559a.637.637 0 0 0-.443.348c-.048.088-.05.193-.05 2.093s.002 2.005.05 2.093c.253.477.893.486 1.123.016l.068-.138-.007-2.001-.007-2.001-.08-.128a.653.653 0 0 0-.654-.282m3.008 0a.636.636 0 0 0-.426.318l-.075.142v3.957l.069.138c.232.466.868.455 1.121-.021.048-.088.05-.193.05-2.093 0-1.998 0-2-.058-2.107a.628.628 0 0 0-.681-.334" />
    </svg>
  );
};
export default IcoTrash16;
