import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoVisaPassport24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M6.289 4.205c-.551.091-1.043.53-1.215 1.085-.071.226-.074.527-.074 6.706 0 5.793.007 6.492.064 6.687.159.545.601.968 1.146 1.097.375.088 11.205.088 11.58 0a1.585 1.585 0 0 0 1.146-1.097c.057-.195.064-.894.064-6.687 0-6.179-.003-6.48-.074-6.706a1.572 1.572 0 0 0-1.251-1.088c-.317-.051-11.077-.049-11.386.003M17.48 12v6.32H6.52V5.68h10.96V12m-5.559-4.837c-.152.033-.332.208-.561.545l-.14.205-.3-.096c-.474-.152-.737-.121-.944.109-.142.157-.173.316-.137.701.019.194.022.365.007.379a2.894 2.894 0 0 1-.335.153c-.349.144-.532.317-.574.544-.045.238.037.413.322.696.143.142.261.282.261.311 0 .029-.074.192-.164.361-.143.271-.162.334-.147.513.032.399.248.559.818.607l.353.029.052.22c.11.461.135.525.252.658.142.162.348.24.539.204.075-.014.283-.123.461-.242l.323-.217.292.22c.348.262.489.311.723.252.261-.065.394-.234.514-.651.057-.196.107-.359.113-.364.006-.004.193-.025.415-.047.445-.043.605-.118.719-.339.103-.199.08-.405-.085-.775l-.151-.34.303-.311c.331-.34.397-.495.313-.735-.082-.236-.162-.312-.524-.493-.401-.202-.383-.165-.316-.641.039-.286.036-.318-.05-.492-.104-.209-.332-.365-.533-.366a1.988 1.988 0 0 0-.4.079c-.154.044-.307.08-.34.079-.033 0-.154-.144-.269-.319-.264-.4-.473-.513-.81-.437m.544 2.079c.08.026.223.038.32.028l.175-.02v.156c0 .239.095.418.298.561l.182.128-.097.103a.63.63 0 0 0-.127.699l.056.134-.164.026a.639.639 0 0 0-.512.375 4.126 4.126 0 0 1-.1.202c-.006.008-.07-.027-.142-.078-.179-.127-.481-.128-.685-.001-.126.078-.149.082-.149.027 0-.124-.164-.385-.288-.461a.938.938 0 0 0-.275-.102l-.15-.025.061-.146a.712.712 0 0 0-.09-.674c-.062-.082-.096-.155-.076-.162.257-.086.458-.374.458-.655 0-.135.012-.161.064-.141a.59.59 0 0 0 .702-.169l.134-.151.131.149a.764.764 0 0 0 .274.197m-2.932 5.459c-.412.247-.484.759-.159 1.128.225.258.156.251 2.626.251 2.47 0 2.401.007 2.626-.251.326-.371.252-.886-.163-1.129l-.17-.1h-2.297l-2.296.001-.167.1"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoVisaPassport24;
