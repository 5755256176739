import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoIdBack40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path d="M7.898 3.398c-2.03.303-3.789 1.872-4.378 3.906l-.153.529v24.334l.153.529a5.556 5.556 0 0 0 3.784 3.784l.529.153h24.334l.529-.153a5.578 5.578 0 0 0 3.784-3.784l.153-.529V7.833l-.153-.529c-.521-1.8-1.932-3.22-3.759-3.785l-.488-.151-12-.01c-6.6-.005-12.15.013-12.335.04m24.177 3.444c.442.205.826.579 1.04 1.014l.185.377v23.534l-.187.381c-.23.467-.672.869-1.156 1.05-.359.134-.462.135-11.957.135-11.495 0-11.598-.001-11.957-.135a2.197 2.197 0 0 1-1.156-1.05l-.187-.381V8.233l.14-.303c.273-.589.762-1.012 1.357-1.173.236-.064 2.885-.079 11.936-.07l11.634.013.308.142m-20.132 3.229c-.499.201-.81.647-.81 1.162 0 .622.386 1.096.998 1.227.48.103 15.258.103 15.738 0 .392-.084.77-.378.908-.707.223-.535.022-1.219-.45-1.532l-.284-.188-7.971-.013c-4.909-.007-8.032.012-8.129.051m5.224 4.506c-.483.255-.7.604-.7 1.124 0 .32.166.721.378.913.359.324.266.319 5.822.319 5.555 0 5.462.005 5.821-.319.426-.385.501-1.187.154-1.641a1.55 1.55 0 0 0-.443-.374c-.254-.129-.397-.132-5.546-.13-4.688.003-5.309.015-5.486.108m-4.705 10.18c-.46.125-.91.418-1.113.725l-.182.274-.021 1.494c-.019 1.421-.013 1.508.126 1.794.089.183.265.388.453.524.62.45.274.432 8.275.432 8.001 0 7.655.018 8.275-.432.188-.136.364-.341.453-.524.139-.286.145-.373.126-1.8l-.021-1.5-.202-.288c-.145-.206-.334-.356-.66-.523l-.457-.233-7.374-.013c-5.703-.01-7.442.006-7.678.07" />
    </svg>
  );
};
export default IcoIdBack40;
