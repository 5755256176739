import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoPassport24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M6.289 4.205c-.551.091-1.043.53-1.215 1.085-.071.226-.074.527-.074 6.706 0 5.793.007 6.492.064 6.687.159.545.601.968 1.146 1.097.375.088 11.205.088 11.58 0a1.585 1.585 0 0 0 1.146-1.097c.057-.195.064-.894.064-6.687 0-6.179-.003-6.48-.074-6.706a1.572 1.572 0 0 0-1.251-1.088c-.317-.051-11.077-.049-11.386.003M17.48 12v6.32H6.52V5.68h10.96V12m-6.099-4.014c-1.47.315-2.461 1.824-2.164 3.293.132.649.378 1.081.884 1.554.538.503 1.146.739 1.899.739.739.001 1.358-.237 1.883-.723.682-.632 1.001-1.399.944-2.271a2.839 2.839 0 0 0-3.446-2.592m1.214 1.57c.226.117.494.395.611.634.136.279.15.713.032 1.03a1.431 1.431 0 0 1-.77.774 1.275 1.275 0 0 1-1.392-.31c-.363-.362-.497-.907-.336-1.363a1.45 1.45 0 0 1 .808-.826c.237-.088.826-.054 1.047.061m-3.062 5.145c-.412.247-.484.759-.159 1.128.225.258.156.251 2.626.251 2.47 0 2.401.007 2.626-.251.326-.371.252-.886-.163-1.129l-.17-.1h-2.297l-2.296.001-.167.1"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoPassport24;
