import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoSparkles24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M18.107 2.89c-.046.034-.101.207-.165.523-.248 1.22-.877 1.833-2.106 2.049-.497.088-.632.226-.425.433.067.067.178.105.41.143 1.246.201 1.953.897 2.14 2.108.054.345.114.474.24.514.146.047.271-.074.299-.289.16-1.24.719-1.965 1.728-2.245.158-.044.392-.092.52-.107.296-.035.412-.106.412-.254 0-.165-.104-.232-.44-.282-1.269-.19-1.957-.871-2.182-2.16-.032-.186-.088-.37-.125-.41a.228.228 0 0 0-.306-.023m-7.16 2.351a.813.813 0 0 0-.442.374c-.036.076-.102.378-.147.67-.542 3.537-2.236 5.218-5.798 5.755-.443.067-.568.101-.696.191a.71.71 0 0 0-.31.601c-.002.219.043.331.201.502.159.172.281.219.732.286.721.107 1.238.223 1.782.401 2.47.81 3.748 2.5 4.11 5.431.064.527.142.713.361.862.307.211.686.18.942-.076.182-.182.209-.271.315-1.058.169-1.249.564-2.341 1.142-3.16.452-.639 1.214-1.281 1.965-1.655.752-.374 1.768-.657 2.676-.744.385-.037.577-.091.73-.207.413-.312.348-.997-.117-1.238-.065-.033-.319-.087-.565-.119-1.052-.137-1.75-.319-2.485-.647-1.91-.851-3.023-2.571-3.384-5.23-.061-.448-.13-.626-.302-.777a.739.739 0 0 0-.71-.162m.372 4.169c.317.71.789 1.386 1.377 1.974.56.56 1.099.945 1.815 1.294l.309.151-.3.15c-1.48.742-2.519 1.779-3.204 3.194l-.153.316-.158-.324c-.693-1.417-1.777-2.473-3.265-3.18l-.32-.152.42-.208c.794-.395 1.274-.734 1.841-1.305.552-.555 1.106-1.362 1.365-1.986.049-.118.101-.214.116-.214s.086.13.157.29"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoSparkles24;
