import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoInfo24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M10.842 3.625A8.48 8.48 0 0 0 4.42 8.26c-.597 1.214-.865 2.373-.865 3.74s.268 2.526.865 3.74a8.5 8.5 0 0 0 5.648 4.495 8.808 8.808 0 0 0 3.864 0 8.507 8.507 0 0 0 6.451-7.103c.081-.567.081-1.697 0-2.264a8.462 8.462 0 0 0-7.275-7.246c-.583-.076-1.693-.075-2.266.003m2.298 1.521c1.923.298 3.725 1.509 4.783 3.215.457.736.789 1.635.941 2.543.09.536.09 1.656 0 2.192a6.994 6.994 0 0 1-3.207 4.816 7.199 7.199 0 0 1-2.637.974c-.413.066-1.628.066-2.04 0-2.371-.383-4.372-1.904-5.322-4.045a7.785 7.785 0 0 1-.523-1.735c-.086-.542-.086-1.67 0-2.212.359-2.276 1.915-4.3 4.024-5.236a7.388 7.388 0 0 1 2.361-.593c.369-.025 1.198.016 1.62.081m-1.499 2.846c-.309.17-.453.496-.384.866.054.287.429.58.743.58.279 0 .62-.234.717-.491a.807.807 0 0 0-.262-.894c-.195-.148-.601-.179-.814-.061m-1.051 2.531a.792.792 0 0 0-.248.228c-.347.526.03 1.165.688 1.168l.21.001v3.744l.099.198c.145.289.326.398.661.398.338 0 .517-.109.662-.403l.1-.203-.011-2.36-.011-2.36-.11-.166c-.195-.294-.315-.328-1.158-.326-.65.001-.749.01-.882.081"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoInfo24;
