import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoFlag24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M8.64 3.588c-.912.096-2.42.556-2.843.868-.233.172-.419.452-.519.783-.078.256-.079.393-.069 7.481l.011 7.22.093.15c.339.544 1.149.445 1.36-.166.034-.098.047-.797.047-2.505v-2.368l.239-.088c.39-.142.853-.273 1.311-.371.343-.073.551-.092 1.03-.091.55.002.628.011.94.115.187.062.832.346 1.433.631 1.111.526 1.818.796 2.287.871.941.151 2.404-.072 3.64-.555.678-.265.952-.527 1.121-1.076.076-.247.079-.384.079-4.203 0-4.305.005-4.204-.228-4.601-.292-.499-.911-.843-1.516-.843-.1 0-.454.07-.789.157-.718.185-1.101.243-1.603.243-.635 0-.998-.122-2.484-.833-1.456-.696-1.992-.852-2.894-.841-.278.004-.569.013-.646.022m1.232 1.515c.394.08.853.266 1.948.788 1.316.628 1.848.805 2.583.858.535.038 1.595-.106 2.28-.31.311-.092.489-.1.552-.025.033.04.043 1.084.035 3.87l-.01 3.816-.56.187a7.09 7.09 0 0 1-1.86.379c-.684.038-1.096-.087-2.46-.746-1.337-.646-1.867-.837-2.55-.919-.681-.081-1.804.058-2.716.336a4.93 4.93 0 0 1-.366.103c-.016 0-.024-1.759-.018-3.91l.01-3.91.56-.187c1.025-.343 1.935-.46 2.572-.33"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoFlag24;
