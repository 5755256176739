import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoWriting24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path d="M17.84 3.296c-.498.111-.496.109-3.153 2.758-1.402 1.399-2.539 2.565-2.6 2.666l-.107.18-.012 1.414c-.014 1.528.005 1.73.179 1.958.213.28.252.286 1.95.287 1.43.001 1.527-.004 1.68-.078.109-.053 1.003-.921 2.706-2.63 2.386-2.394 2.55-2.567 2.658-2.811.149-.34.183-.843.079-1.166-.117-.361-.264-.541-1.188-1.462-.953-.948-1.083-1.043-1.55-1.132-.262-.049-.361-.047-.642.016m-12.76.998a2.207 2.207 0 0 0-.414.146c-.269.136-.636.539-.752.827L3.82 5.5l-.012 6.86c-.009 5.097-.001 6.917.034 7.08A1.634 1.634 0 0 0 5.2 20.718c.358.057 13.319.058 13.68 0a1.646 1.646 0 0 0 1.399-1.418c.055-.39.054-6.112-.001-6.311-.053-.188-.255-.424-.426-.495a.792.792 0 0 0-.925.278l-.107.162-.011 3.1c-.008 2.178-.024 3.116-.055 3.153-.063.076-13.372.079-13.422.003-.042-.065-.064-12.905-.022-13.192l.031-.218 3.1-.02 3.099-.02.17-.091c.357-.19.501-.624.323-.967a1.135 1.135 0 0 0-.247-.3l-.153-.122-3.167-.007c-2.353-.005-3.223.005-3.386.041M19.03 5.53c.423.423.77.796.77.829 0 .033-1.048 1.108-2.33 2.39l-2.329 2.331H13.48V9.379l2.311-2.309c1.49-1.49 2.339-2.31 2.389-2.31.049 0 .371.291.85.77" />
    </svg>
  );
};
export default IcoWriting24;
