import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoCake24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M11.74 2.818c-.239.088-1.876 1.752-2.048 2.082-.221.425-.31.787-.308 1.26.003.727.219 1.271.71 1.789.305.321.573.512.916.652l.23.094V9.4H8.642c-2.545 0-2.604.002-2.824.084a1.783 1.783 0 0 0-.762.606c-.247.36-.256.442-.256 2.283 0 1.845.011 1.936.274 2.318l.126.183v2.038c0 1.208.016 2.125.041 2.253a1.62 1.62 0 0 0 1.139 1.237c.3.087 10.94.087 11.24 0a1.62 1.62 0 0 0 1.139-1.237c.025-.128.041-1.045.041-2.253v-2.038l.126-.183c.263-.382.274-.473.274-2.318 0-1.841-.009-1.923-.256-2.283a1.783 1.783 0 0 0-.762-.606c-.22-.082-.279-.084-2.824-.084H12.76l.001-.35.002-.35.368-.175c.31-.148.424-.231.714-.52.281-.282.374-.407.505-.685.213-.447.266-.693.261-1.2-.003-.311-.026-.493-.091-.7-.171-.548-.309-.736-1.216-1.653-.464-.47-.893-.879-.953-.911a.838.838 0 0 0-.611-.038m.727 2.232c.245.248.485.53.534.627.365.733-.179 1.603-1.001 1.602a1.097 1.097 0 0 1-1.11-1.022c-.036-.439.048-.601.603-1.167.264-.269.491-.49.504-.49.014 0 .225.203.47.45m5.233 7.326v1.476l-.32.177c-.176.098-.519.284-.762.413l-.441.236-.639-.385c-.944-.569-1.002-.592-1.458-.592-.457.001-.501.019-1.397.557-.349.21-.657.382-.683.382-.026 0-.334-.172-.683-.382-.896-.538-.94-.556-1.397-.557-.456 0-.514.023-1.458.592l-.639.385-.441-.236a53.384 53.384 0 0 1-.762-.413l-.32-.177-.011-1.436c-.005-.79-.001-1.458.009-1.486.016-.04 1.167-.048 5.711-.04l5.691.01v1.476m-7.089 3.21c.826.497.956.549 1.389.549.433 0 .563-.052 1.389-.549.354-.212.667-.386.697-.386.03 0 .342.173.694.385.897.54.921.549 1.4.552.39.003.409-.001.75-.165l.351-.169-.011 1.569-.01 1.568-5.27.01-5.27.01v-3.156l.35.168c.341.164.36.168.75.165.479-.003.503-.012 1.4-.552.352-.212.664-.385.694-.385.03 0 .343.174.697.386"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCake24;
