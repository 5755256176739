import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoPasskey40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M19.267 3.374c-1.757.217-3.258.915-4.491 2.088-2.053 1.953-2.794 5.056-1.863 7.805.878 2.594 3.176 4.522 5.92 4.968 4.259.693 8.274-2.459 8.624-6.768.248-3.053-1.324-5.895-4.025-7.277-.703-.36-1.2-.537-1.928-.687-.53-.11-1.801-.183-2.237-.129M21.2 6.837c.639.19 1.14.503 1.704 1.063.87.864 1.227 1.718 1.227 2.933 0 1.216-.356 2.067-1.231 2.94-1.041 1.04-2.357 1.43-3.8 1.127-.793-.167-1.388-.506-2.033-1.16-.606-.613-.912-1.128-1.101-1.855-.128-.491-.128-1.612 0-2.104.392-1.505 1.749-2.774 3.257-3.046.471-.085 1.533-.03 1.977.102m7.972 11.562c-1.424.259-2.811 1.225-3.512 2.447-.624 1.088-.816 2.567-.492 3.787.325 1.222 1.236 2.393 2.362 3.038l.399.229.002 3.096.002 3.096.351.338c.193.185.587.517.876.737.471.358.559.4.84.4.281 0 .369-.042.84-.4.289-.22.683-.552.876-.737l.351-.338V32.345l-.217-.181c-.119-.1-.394-.345-.61-.545l-.393-.363.61-.613.61-.612v-1.082c0-.595.026-1.082.058-1.082.132 0 1.067-.673 1.4-1.007 2.437-2.451 1.746-6.512-1.364-8.007a6.728 6.728 0 0 0-.9-.358c-.443-.122-1.644-.176-2.089-.096m-10.105 1.645a18.65 18.65 0 0 0-2.2.296c-5.219 1.098-9.25 5.067-10.608 10.447-.217.86-.232 1.136-.092 1.731a3.226 3.226 0 0 0 2.336 2.38c.34.089 1.356.102 7.732.102 8.261 0 7.721.033 8.26-.505.668-.669.666-1.657-.006-2.328-.528-.529-.075-.5-8.008-.5-6.084 0-7.024-.013-6.992-.096.02-.053.071-.225.112-.383.465-1.788 1.602-3.697 2.954-4.962 1.862-1.742 3.97-2.649 6.578-2.829 1.318-.091 1.64-.181 2.024-.565.673-.673.673-1.658-.001-2.332-.45-.451-.866-.541-2.089-.456m11.5 1.733c.615.21 1.1.896 1.1 1.556 0 .879-.788 1.667-1.667 1.667-.403 0-.869-.199-1.168-.498a1.64 1.64 0 0 1 .001-2.338c.463-.463 1.096-.604 1.734-.387"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoPasskey40;
