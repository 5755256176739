import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoPassportCard24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M5.414 5.041c-.561.112-1.06.6-1.193 1.167-.089.377-.09 11.205-.001 11.582.129.545.552.987 1.097 1.146.195.057.894.064 6.683.064s6.488-.007 6.683-.064a1.585 1.585 0 0 0 1.097-1.146c.089-.377.088-11.205-.001-11.582-.111-.471-.434-.848-.922-1.077l-.237-.111-6.52-.006c-3.586-.003-6.595.009-6.686.027M18.31 12.01l-.01 5.49-6.22.011c-3.421.006-6.26.001-6.31-.011l-.09-.022V6.52h12.64l-.01 5.49m-6.929-4.024c-1.47.315-2.461 1.824-2.164 3.293.132.649.378 1.081.884 1.554.538.503 1.146.739 1.899.739.739.001 1.358-.237 1.883-.723.673-.623.941-1.239.933-2.149-.003-.403-.021-.532-.109-.801a2.858 2.858 0 0 0-3.326-1.913m1.214 1.57c.226.117.479.38.604.628.143.283.159.712.039 1.036a1.431 1.431 0 0 1-.77.774 1.275 1.275 0 0 1-1.392-.31c-.363-.362-.497-.907-.336-1.363a1.45 1.45 0 0 1 .808-.826c.237-.088.826-.054 1.047.061m-3.062 5.145c-.412.247-.484.759-.159 1.128.225.258.156.251 2.626.251 2.47 0 2.401.007 2.626-.251.326-.371.252-.886-.163-1.129l-.17-.1h-2.297l-2.296.001-.167.1"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoPassportCard24;
