const IcoArrowCustom = () => {
  return (
    <svg width="47" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.35 53.306c.093-.223.186-.275.2-.38.484-4.697 1.756-9.206 2.883-13.755 1.168-4.693 2.335-9.386 4.04-13.945 1.011-2.515 2.062-5.004 3.649-7.188.489-.64 1.044-1.294 1.73-1.802 1.33-1.043 2.878-.798 3.869.612.416.567.715 1.225.921 1.934.349 1.092.566 2.209.849 3.313.141.552.282 1.104.49 1.644.44 1.04 1.174 1.306 2.187.904 1.013-.401 1.791-1.132 2.452-1.942.925-1.203 1.484-2.54 1.925-3.954.962-3.12 1.53-6.333 1.614-9.588.047-1.339.133-2.65.218-3.963.016-.446.163-.918.362-1.298.093-.223.527-.443.71-.377.25.054.458.423.64.66.052.092.012.236-.002.341.002 2.114.017 4.293-.1 6.498-.165 3.714-.854 7.36-2.29 10.844-.705 1.637-1.684 3.089-3.16 4.092a7.364 7.364 0 0 1-2.211 1.077c-1.815.49-3.465-.281-4.27-1.966-.402-.842-.62-1.788-.864-2.695-.349-1.092-.632-2.196-.94-3.261-.091-.29-.352-.58-.508-.856-.657.128-.896.651-1.227 1.056-1.654 2.368-2.85 4.987-3.704 7.778-1.777 5.767-3.45 11.547-5.161 17.3-.655 2.242-1.35 4.456-2.072 6.881.303-.195.487-.3.698-.443.738-.586 1.437-1.2 2.215-1.76.33-.234.698-.442 1.119-.558.447-.155.919-.008 1.074.439.13.316.075.735-.032 1.063-.107.328-.37.55-.634.771-2.005 1.788-3.971 3.602-6.015 5.363-1.056.887-2.183.697-2.833-.54-.364-.646-.649-1.409-.724-2.144-.418-2.68-.745-5.413-1.032-8.118-.063-.5-.007-1.09.1-1.588.161-.577.687-.68.96-.153.376.711.766 1.488.958 2.303.463 1.853.82 3.693 1.244 5.52.128.486.218.946.347 1.433.117.08.209.198.325.448Z"
        fill="#000"
      />
    </svg>
  );
};
export default IcoArrowCustom;
