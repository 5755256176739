import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoWarning24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M11.62 3.574a1.566 1.566 0 0 0-.956.698c-.094.147-1.758 2.977-3.696 6.288-3.797 6.485-3.682 6.272-3.637 6.759.045.478.332.971.702 1.205.103.066.301.154.441.197.249.077.412.079 7.526.079 7.129 0 7.277-.002 7.527-.079a1.599 1.599 0 0 0 1.142-1.402c.045-.487.164-.266-3.696-6.859-1.97-3.366-3.64-6.201-3.709-6.3-.141-.201-.548-.494-.775-.56-.242-.07-.654-.082-.869-.026m3.98 7.514c1.947 3.325 3.546 6.079 3.553 6.119.012.07-.239.073-7.153.073-6.938 0-7.165-.002-7.153-.074C4.873 17.06 11.939 5.042 12 5.042c.037 0 1.39 2.271 3.6 6.046m-3.97-1.605a.848.848 0 0 0-.26.245l-.11.166-.013 1.603c-.008 1.075.002 1.652.032 1.751.089.304.412.55.721.55s.632-.246.721-.55c.03-.099.04-.676.032-1.751l-.013-1.603-.11-.166c-.207-.313-.661-.424-1-.245m.15 4.713a.963.963 0 0 0-.657.564c-.164.363-.075.764.234 1.054.386.36.9.36 1.286 0a.93.93 0 0 0 .045-1.348c-.214-.226-.617-.346-.908-.27"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoWarning24;
