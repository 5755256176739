import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoStore24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M6.8 4.437a1.615 1.615 0 0 0-.614.376c-.091.093-.704.867-1.361 1.721-.87 1.131-1.211 1.604-1.254 1.739-.083.266-.044 1.35.064 1.771.127.49.477 1.167.701 1.353.058.048.064.381.064 3.461 0 3.79-.011 3.632.271 4.057.176.264.455.49.759.612l.23.093h12.68l.23-.093c.304-.122.583-.348.759-.612.282-.425.271-.267.271-4.057 0-3.137.005-3.413.067-3.463.242-.199.64-1.013.731-1.495.079-.421.098-1.413.031-1.626-.043-.136-.384-.609-1.254-1.739-.657-.854-1.27-1.629-1.361-1.722a1.917 1.917 0 0 0-.38-.281l-.214-.112-5.16-.007c-2.838-.004-5.205.007-5.26.024m10.66 2.33c.374.487.686.9.693.919.008.019-2.761.034-6.153.034-3.487 0-6.161-.015-6.153-.035.007-.019.319-.432.693-.918l.68-.884h9.56l.68.884M8.68 9.295c0 .395-.193.858-.487 1.169a1.757 1.757 0 0 1-1.322.576c-.927 0-1.727-.692-1.815-1.57l-.027-.27H8.68v.095m5.111.181c-.05.695-.638 1.356-1.355 1.522a2.504 2.504 0 0 1-.872 0c-.721-.167-1.317-.838-1.357-1.528l-.016-.27h3.62l-.02.276m5.169-.158c0 .714-.556 1.426-1.282 1.643-.464.138-.983.09-1.417-.131a2.29 2.29 0 0 1-.405-.317 1.662 1.662 0 0 1-.52-1.043l-.027-.27h3.651v.118M10 11.887c1.124.871 2.876.871 4 0 .099-.077.271-.227.381-.334l.202-.193.218.224c.79.807 1.932 1.124 3.149.873l.13-.027v5.69h-3.591l-.019-1.09c-.016-.97-.028-1.119-.103-1.35-.266-.816-1.005-1.487-1.827-1.66a2.506 2.506 0 0 0-2.907 1.66c-.077.233-.088.375-.104 1.35l-.017 1.09H5.92v-5.69l.13.027c.807.166 1.647.076 2.29-.246.284-.143.647-.41.879-.648l.198-.203.202.193c.11.107.282.257.381.334m2.391 3.655c.197.082.401.28.493.478.066.142.074.27.075 1.13l.001.97h-1.92v-.961c0-1.062.015-1.144.252-1.394.07-.074.2-.168.288-.209.2-.093.606-.1.811-.014"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoStore24;
