import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoIdCard24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M4.852 5.24c-.287.042-.554.18-.802.413a1.515 1.515 0 0 0-.452.728c-.087.299-.087 10.94 0 11.239a1.62 1.62 0 0 0 1.237 1.139c.297.056 14.033.056 14.33 0a1.62 1.62 0 0 0 1.237-1.139c.087-.299.087-10.94 0-11.239a1.515 1.515 0 0 0-.452-.728 1.451 1.451 0 0 0-.838-.415c-.304-.044-13.959-.042-14.26.002M18.94 12v5.26H5.06l-.01-5.22c-.006-2.871-.002-5.243.009-5.27.015-.04 1.426-.048 6.95-.04l6.931.01V12m-6.071-2.503a1.596 1.596 0 0 0-.911.97c-.071.205-.078.343-.078 1.533 0 1.198.007 1.327.079 1.537.152.443.506.81.955.992.22.089.262.091 1.586.091s1.366-.002 1.586-.091c.449-.182.803-.549.955-.992.072-.21.079-.339.079-1.537s-.007-1.327-.079-1.537a1.62 1.62 0 0 0-.914-.968c-.223-.091-.251-.093-1.627-.093-1.382 0-1.403.001-1.631.095m-5.503.346a.745.745 0 0 0-.424.987c.079.19.163.281.346.374.136.07.251.076 1.372.076 1.357 0 1.391-.005 1.61-.255.108-.122.2-.382.18-.505a9.673 9.673 0 0 1-.026-.167c-.029-.181-.184-.371-.387-.475-.143-.073-.239-.078-1.365-.075-.667.001-1.254.019-1.306.04M15.6 12v1.12h-1.096c-.962 0-1.098-.008-1.12-.063-.013-.035-.024-.511-.024-1.057 0-.546.011-1.022.024-1.057.022-.055.158-.063 1.12-.063H15.6V12m-8.3.786c-.186.099-.265.181-.345.354-.191.416.016.898.44 1.023.093.027.586.037 1.371.029l1.227-.012.153-.123a.742.742 0 0 0 0-1.194l-.153-.123-1.287-.009c-1.116-.008-1.302-.001-1.406.055"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoIdCard24;
