import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoBuilding16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M3.631 1.895c-.36.047-.756.329-.936.666-.169.317-.161.041-.161 5.419l-.001 4.9-.42.001c-.478 0-.564.024-.736.2-.289.298-.193.782.196.989.091.048.299.05 6.427.05h6.333l.107-.058c.475-.256.46-.911-.025-1.131-.09-.042-.177-.05-.528-.05l-.42-.001-.001-4.9c0-5.378.008-5.102-.161-5.419a1.507 1.507 0 0 0-.591-.562 1.391 1.391 0 0 0-.369-.105c-.244-.032-8.47-.03-8.714.001m8.576 6.112.006 4.873H3.787V8.018c0-2.674.008-4.87.018-4.88.009-.01 1.902-.015 4.206-.011l4.189.006.007 4.874M5.715 4.56a.612.612 0 0 0-.442.333.629.629 0 0 0 .312.843c.096.044.182.049.748.049.567 0 .653-.005.749-.049a.622.622 0 0 0-.002-1.14c-.089-.039-.2-.048-.68-.053a6.217 6.217 0 0 0-.685.017m3.334 0a.614.614 0 0 0-.443.333.629.629 0 0 0 .312.843c.096.044.182.049.749.049.566 0 .652-.005.748-.049a.622.622 0 0 0-.002-1.14c-.089-.039-.2-.048-.68-.053a6.184 6.184 0 0 0-.684.017M5.715 7.227a.607.607 0 0 0-.442.333.63.63 0 0 0 .312.843c.096.043.182.049.748.049.567 0 .653-.006.749-.049a.622.622 0 0 0-.002-1.14c-.089-.04-.2-.048-.68-.054a6.39 6.39 0 0 0-.685.018m3.334 0a.609.609 0 0 0-.443.333.63.63 0 0 0 .312.843c.096.043.182.049.749.049.566 0 .652-.006.748-.049a.622.622 0 0 0-.002-1.14c-.089-.04-.2-.048-.68-.054a6.356 6.356 0 0 0-.684.018M5.715 9.893a.611.611 0 0 0-.442.334.628.628 0 0 0 .312.842c.096.044.182.05.748.05.567 0 .653-.006.749-.05a.622.622 0 0 0-.002-1.14c-.089-.039-.2-.048-.68-.053a6.495 6.495 0 0 0-.685.017m3.334 0a.613.613 0 0 0-.443.334.628.628 0 0 0 .312.842c.096.044.182.05.749.05.566 0 .652-.006.748-.05a.622.622 0 0 0-.002-1.14c-.089-.039-.2-.048-.68-.053a6.46 6.46 0 0 0-.684.017"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoBuilding16;
