import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoEmail24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path d="M4.441 5.104c-.524.141-.918.518-1.105 1.056-.073.212-.076.417-.076 5.84s.003 5.628.076 5.84c.186.536.592.924 1.113 1.063.315.084 14.787.084 15.102 0a1.632 1.632 0 0 0 1.113-1.063c.073-.212.076-.417.076-5.84s-.003-5.628-.076-5.84c-.19-.547-.583-.917-1.122-1.058-.212-.055-1.067-.062-7.56-.059-6.314.002-7.352.01-7.541.061M18.56 6.537c0 .052-6.497 5.323-6.56 5.323-.063 0-6.56-5.271-6.56-5.323 0-.009 2.952-.017 6.56-.017 3.608 0 6.56.008 6.56.017M7.944 10.542c1.914 1.565 3.236 2.619 3.359 2.678.43.203.964.203 1.394 0 .123-.059 1.447-1.114 3.363-2.682l3.16-2.585.01 4.709c.008 3.447-.002 4.724-.035 4.764-.062.075-14.312.081-14.387.006-.037-.037-.048-1.148-.048-4.76 0-2.592.007-4.712.014-4.712.008 0 1.435 1.162 3.17 2.582" />
    </svg>
  );
};
export default IcoEmail24;
