import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoBuilding24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M6.436 4.694c-.277.114-.42.214-.614.43-.189.208-.334.52-.383.823-.023.14-.039 2.571-.039 6.1v5.867l-.573.013c-.548.012-.581.018-.739.123a.772.772 0 0 0-.274.922c.082.197.309.373.545.424.29.062 14.992.062 15.282 0 .236-.051.463-.227.545-.424.134-.321.014-.732-.27-.919-.163-.109-.192-.113-.743-.126l-.573-.013v-5.867c0-3.646-.015-5.958-.04-6.107a1.656 1.656 0 0 0-.911-1.213l-.229-.107-5.38-.009-5.38-.01-.224.093M17.07 12.01l.01 5.91H6.92v-5.893c0-3.242.012-5.906.027-5.92.015-.015 2.296-.023 5.07-.017l5.043.01.01 5.91M9.246 7.961a.774.774 0 0 0-.432 1.011c.136.326.465.468 1.083.468.654 0 .956-.124 1.106-.455a.743.743 0 0 0-.251-.931c-.162-.112-.174-.114-.788-.123-.343-.005-.666.009-.718.03m4.134.014a.748.748 0 0 0-.383 1.01c.15.331.452.455 1.106.455.618 0 .947-.142 1.083-.468.134-.321.014-.732-.27-.919-.166-.11-.184-.113-.793-.123-.459-.007-.655.005-.743.045m-3.96 3.282a1.281 1.281 0 0 1-.149.04.747.747 0 0 0-.256.153c-.42.368-.316.993.205 1.234.233.108 1.167.108 1.4 0 .295-.137.46-.381.46-.684a.667.667 0 0 0-.193-.494c-.2-.212-.303-.241-.887-.253a6.167 6.167 0 0 0-.58.004m4.16 0a1.281 1.281 0 0 1-.149.04.747.747 0 0 0-.256.153c-.42.368-.316.993.205 1.234.233.108 1.167.108 1.4 0 .295-.137.46-.381.46-.684a.667.667 0 0 0-.193-.494c-.2-.212-.303-.241-.887-.253a6.167 6.167 0 0 0-.58.004m-4.4 3.406c-.282.146-.421.367-.419.666a.742.742 0 0 0 .323.618c.165.11.187.113.784.126.566.012.629.006.796-.075.541-.262.549-1.046.014-1.319-.134-.068-.236-.079-.766-.079-.476 0-.639.014-.732.063m4.16.002a.702.702 0 0 0-.364.401.74.74 0 0 0 .36.932c.167.081.23.087.796.075.597-.013.619-.016.784-.126a.744.744 0 0 0 .323-.627c.001-.228-.097-.42-.293-.577l-.153-.123-.667-.01c-.55-.008-.687.002-.786.055"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoBuilding24;
