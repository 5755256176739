import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoShield16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M5.223 2.103c-1.403.468-2.613.884-2.69.926a1.338 1.338 0 0 0-.641.935c-.019.129-.025.898-.018 2.374.011 2.335.011 2.335.154 2.942.251 1.062.85 2.068 1.743 2.927.795.764 1.634 1.342 3.269 2.253.729.406.775.426.96.426.185 0 .231-.02.96-.426 1.635-.911 2.474-1.489 3.269-2.253.893-.859 1.492-1.865 1.743-2.927.143-.607.143-.607.154-2.942.007-1.476.001-2.245-.018-2.374a1.324 1.324 0 0 0-.66-.943c-.085-.045-1.295-.461-2.689-.924-2.528-.842-2.535-.844-2.76-.844-.225.001-.233.003-2.776.85m5.244 1.212 2.4.805v2.12c0 2.256-.002 2.302-.134 2.827a3.671 3.671 0 0 1-.348.901c-.477.904-1.189 1.62-2.445 2.455-.511.34-1.864 1.124-1.941 1.124-.034 0-.61-.316-1.132-.621-2.293-1.34-3.383-2.583-3.675-4.19-.057-.315-.058-.375-.058-2.469L3.133 4.12l2.4-.805c1.32-.442 2.43-.804 2.467-.804.037 0 1.147.362 2.467.804M9.64 5.746c-.059.022-.112.04-.119.04-.007 0-.502.489-1.1 1.087L7.333 7.959l-.406-.403c-.224-.222-.449-.426-.5-.452a.616.616 0 0 0-.269-.05c-.15-.001-.195.011-.306.08a.623.623 0 0 0-.3.495c-.02.271.034.347.787 1.095.617.613.671.66.806.7.179.053.197.053.377 0 .139-.04.196-.094 1.472-1.366 1.476-1.472 1.475-1.471 1.454-1.763-.029-.405-.443-.686-.808-.549"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoShield16;
