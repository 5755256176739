import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoLayer0124 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M11.575 4.737c-.091.028-1.726.814-3.634 1.745-3.806 1.857-3.752 1.827-3.98 2.291-.244.497-.244.955 0 1.454.195.398.383.534 1.498 1.085.544.269 1.077.527 1.185.574.108.046.196.095.196.109-.001.014-.534.286-1.186.604-1.326.648-1.491.762-1.693 1.174-.244.497-.244.955 0 1.454.162.331.425.559.929.805l3.37 1.648c1.628.796 3.073 1.491 3.211 1.544.341.131.717.131 1.058 0 .138-.053 1.583-.748 3.211-1.544l3.37-1.648c.503-.245.766-.473.929-.805.244-.497.244-.955 0-1.454-.202-.412-.367-.526-1.693-1.174-.652-.318-1.186-.59-1.186-.604 0-.014.088-.063.196-.109.108-.047.641-.305 1.185-.574 1.114-.551 1.303-.687 1.498-1.085.244-.497.244-.955 0-1.454-.228-.465-.171-.432-4.005-2.302-1.922-.937-3.566-1.723-3.654-1.747a1.792 1.792 0 0 0-.805.013m3.808 3.083c2.33 1.138 3.317 1.638 3.317 1.683 0 .044-.501.308-1.64.863l-1.64.8-.171-.083a1.873 1.873 0 0 0-.185-.083c-.008 0-.054.096-.102.214l-.087.215-1.401.685c-.771.377-1.434.686-1.474.686-.04 0-.703-.309-1.474-.686l-1.401-.685-.087-.215A1.086 1.086 0 0 0 8.936 11c-.007 0-.091.037-.185.083l-.171.083-1.64-.8C5.8 9.811 5.3 9.547 5.3 9.503c0-.045.983-.545 3.3-1.679 1.815-.889 3.337-1.618 3.383-1.62.045-.002 1.575.725 3.4 1.616m-5.477 5.664c1.618.793 1.728.836 2.105.834.367-.002.468-.042 2.118-.85l1.291-.633 1.64.803c1.124.55 1.64.822 1.64.865 0 .044-1.014.558-3.315 1.68-1.824.889-3.347 1.617-3.385 1.617-.038 0-1.561-.728-3.385-1.617C6.312 15.06 5.3 14.547 5.3 14.503c0-.043.511-.314 1.62-.859.891-.439 1.632-.799 1.646-.801.014-.001.617.287 1.34.641"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoLayer0124;
