import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoUser40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M19.267 3.374c-1.012.125-1.832.372-2.69.811-3.318 1.698-4.866 5.533-3.664 9.082.878 2.594 3.176 4.522 5.92 4.968 4.259.693 8.274-2.459 8.624-6.768a7.474 7.474 0 0 0-5.953-7.964c-.53-.11-1.801-.183-2.237-.129M21.2 6.836c1.304.386 2.523 1.665 2.831 2.971.129.546.129 1.506 0 2.053-.25 1.061-1.171 2.207-2.168 2.697a4.2 4.2 0 0 1-3.73.001c-.385-.188-.63-.376-1.066-.818-.606-.613-.912-1.128-1.101-1.855-.128-.491-.128-1.612 0-2.104.392-1.505 1.749-2.774 3.257-3.046.47-.085 1.532-.03 1.977.101m-2.133 13.203c-.829.072-1.63.181-2.2.301-5.222 1.1-9.249 5.065-10.608 10.447-.217.86-.232 1.136-.092 1.731a3.226 3.226 0 0 0 2.336 2.38c.344.09 1.74.102 11.497.102 9.629 0 11.156-.013 11.487-.099 1.22-.318 2.169-1.341 2.383-2.568.086-.49.051-.877-.144-1.6-.737-2.737-1.939-4.871-3.78-6.71-2.145-2.143-4.945-3.502-8.002-3.885-.616-.078-2.409-.139-2.877-.099m2.066 3.366c1.475.136 2.618.462 3.885 1.108 2.685 1.37 4.644 3.855 5.439 6.904l.066.25H19.987c-9.133 0-10.53-.013-10.498-.096.02-.053.071-.225.112-.383.465-1.788 1.602-3.697 2.954-4.962 2.314-2.165 5.247-3.129 8.578-2.821"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoUser40;
