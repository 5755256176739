import type { RequestError } from '@onefootprint/request';
import request from '@onefootprint/request';
import type { GetD2PRequest, GetD2PResponse } from '@onefootprint/types';
import { AUTH_HEADER } from '@onefootprint/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

type GetD2PStatusPollArgs = {
  enabled?: boolean;
  refetchInterval?: number | false;
  authToken: string;
  options?: {
    onSuccess?: (response: GetD2PResponse) => void;
    onError?: (error: RequestError) => void;
  };
};

const D2P_STATUS_FETCH_INTERVAL = 1000;

const getD2PStatus = async (payload: GetD2PRequest) => {
  const { data: response } = await request<GetD2PResponse>({
    method: 'GET',
    url: '/hosted/onboarding/d2p/status',
    headers: {
      [AUTH_HEADER]: payload.scopedAuthToken,
    },
  });

  return response;
};

const useGetD2PStatus = ({
  authToken,
  enabled = true,
  refetchInterval = D2P_STATUS_FETCH_INTERVAL,
  options = {},
}: GetD2PStatusPollArgs) => {
  const query = useQuery({
    queryKey: ['get-d2p-status', authToken, enabled],
    queryFn: () => getD2PStatus({ scopedAuthToken: authToken ?? '' }),
    enabled: !!authToken && !!enabled,
    refetchInterval,
  });

  useEffect(() => {
    if (query.data) {
      options.onSuccess?.(query.data);
    }
    if (query.isError) {
      options.onError?.(query.error as RequestError);
    }
    // no onSuccess or onError because likely to trigger infinite re-render/loop
  }, [query.isSuccess, query.isError, query.error, query.data]);

  return query;
};

export default useGetD2PStatus;
