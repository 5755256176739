import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoCar24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M6.893 5.517a2.032 2.032 0 0 0-.395.205c-.203.139-.254.206-2.11 2.758l-1.09 1.5-.339.022c-.387.025-.536.089-.714.305-.347.42-.1 1.058.453 1.173l.222.046v2.85c0 2.505.008 2.876.065 3.067.135.455.517.866.968 1.042.205.079.318.09 1.168.106 1.384.026 1.727-.05 2.16-.484.248-.247.393-.539.449-.904l.044-.283h8.452l.043.277c.058.378.199.663.451.913.437.434.768.507 2.159.481.85-.016.963-.027 1.168-.106.451-.176.833-.587.968-1.042.057-.191.065-.558.065-3.01V11.64h.152a.65.65 0 0 0 .334-.115c.463-.294.463-.931.001-1.249-.117-.08-.201-.098-.493-.11l-.352-.014-1.451-2.065c-.798-1.136-1.525-2.138-1.615-2.227a1.911 1.911 0 0 0-.4-.281l-.236-.119-4.96-.009c-4.757-.008-4.968-.006-5.167.066m11.197 3.5 1.47 2.094V17.08h-1.792l-.014-.573c-.013-.547-.019-.581-.124-.739a.846.846 0 0 0-.268-.247c-.157-.08-.221-.081-5.362-.081s-5.205.001-5.362.081a.846.846 0 0 0-.268.247c-.105.158-.111.192-.124.739l-.014.573H4.44v-6.115l1.47-2.022 1.47-2.022 4.62.001 4.62.001 1.47 2.094M6.698 12.154a.738.738 0 0 0-.301 1.116c.208.286.299.308 1.349.323 1.027.014 1.177-.008 1.397-.201.191-.168.257-.309.257-.552a.729.729 0 0 0-.328-.63l-.166-.11-1.023-.01c-.928-.01-1.038-.004-1.185.064m8.414-.034a.732.732 0 0 0-.512.72c0 .243.066.384.257.552.22.193.37.215 1.397.201 1.05-.015 1.141-.037 1.349-.323a.738.738 0 0 0-.301-1.115c-.143-.065-.273-.073-1.122-.071-.528.002-1.009.018-1.068.036"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCar24;
