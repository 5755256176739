import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoShieldFlash40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M19.233 3.512c-.739.205-12.214 4.208-12.501 4.361-.487.259-1.065.836-1.321 1.318-.418.786-.411.684-.411 6.328 0 5.385.023 5.934.303 7.329.855 4.256 3.431 7.541 8.435 10.755 1.515.973 5.191 3.033 5.753 3.224.62.21.896.132 2.37-.675 4.493-2.457 6.601-3.877 8.588-5.78 2.635-2.525 4.14-5.57 4.48-9.067.043-.443.071-2.727.071-5.824 0-5.602.006-5.505-.411-6.29-.256-.482-.834-1.059-1.321-1.318-.21-.112-3.094-1.139-6.408-2.282l-6.027-2.079-.733-.018c-.403-.01-.793-.002-.867.018m6.65 5.27 5.783 1.985v4.809c0 2.903-.027 5.084-.07 5.5-.384 3.768-2.376 6.575-6.698 9.441-1.256.834-4.706 2.816-4.899 2.816-.102 0-2.385-1.263-3.527-1.951-3.067-1.848-5.104-3.608-6.385-5.516-.689-1.026-1.274-2.419-1.512-3.599-.211-1.042-.242-1.904-.241-6.691v-4.809l5.75-1.98c3.162-1.088 5.809-1.981 5.883-1.984.073-.003 2.735.888 5.916 1.979m-5.603 2.302c-.464.149-.707.487-2.621 3.648-1.032 1.705-1.92 3.22-1.973 3.367-.119.33-.076.786.114 1.218.267.602.76.964 1.467 1.079.238.038 1.254.07 2.257.07 1.431.001 1.817.019 1.791.084-.017.046-.88 1.433-1.916 3.083-1.036 1.65-1.939 3.12-2.007 3.267-.156.341-.157.859 0 1.2.431.94 1.599 1.191 2.342.505.335-.31 5.31-8.235 5.485-8.738.295-.846-.143-1.842-.986-2.241-.329-.156-.361-.158-2.483-.159-1.306 0-2.15-.025-2.15-.063 0-.035.59-1.04 1.311-2.233 1.207-1.997 1.314-2.199 1.342-2.539.039-.471-.105-.86-.437-1.179a1.568 1.568 0 0 0-1.536-.369"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoShieldFlash40;
