import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoDollar24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M10.842 3.625A8.48 8.48 0 0 0 4.42 8.26c-.597 1.214-.865 2.373-.865 3.74s.268 2.526.865 3.74a8.5 8.5 0 0 0 5.648 4.495 8.808 8.808 0 0 0 3.864 0 8.507 8.507 0 0 0 6.451-7.103c.081-.567.081-1.697 0-2.264a8.462 8.462 0 0 0-7.275-7.246c-.583-.076-1.693-.075-2.266.003m2.298 1.521c1.923.298 3.725 1.509 4.783 3.215.457.736.789 1.635.941 2.543.09.536.09 1.656 0 2.192a6.994 6.994 0 0 1-3.207 4.816 7.199 7.199 0 0 1-2.637.974c-.413.066-1.628.066-2.04 0-2.371-.383-4.372-1.904-5.322-4.045a7.785 7.785 0 0 1-.523-1.735c-.086-.542-.086-1.67 0-2.212.359-2.276 1.915-4.3 4.024-5.236a7.388 7.388 0 0 1 2.361-.593c.369-.025 1.198.016 1.62.081m-1.527 1.515c-.274.164-.349.329-.367.803l-.015.405-.225.05c-.844.187-1.628.897-1.83 1.656-.071.264-.071.829 0 1.085.122.442.285.713.629 1.049.372.362.5.426 2.234 1.114.788.313.921.379 1.086.537.338.325.336.666-.004.971-.236.211-.493.309-.889.338-.61.044-1.018-.099-1.449-.506-.324-.307-.403-.341-.725-.314-.369.03-.612.284-.65.678-.025.262.091.482.423.8a2.85 2.85 0 0 0 1.089.663l.312.107.014.425c.013.383.026.441.124.591a.75.75 0 0 0 1.005.232c.284-.166.361-.331.379-.806l.014-.401.357-.113a2.759 2.759 0 0 0 1.185-.76c.401-.43.57-.853.57-1.423-.002-.813-.44-1.509-1.229-1.952-.127-.071-.762-.341-1.411-.6-1.062-.424-1.197-.488-1.366-.65-.343-.331-.348-.698-.011-.994.225-.199.463-.283.884-.314.62-.045 1.062.107 1.473.506.297.289.383.333.639.333.555 0 .903-.57.658-1.077-.189-.391-.924-.93-1.523-1.116l-.226-.07-.014-.424c-.013-.358-.029-.447-.108-.574a.775.775 0 0 0-1.033-.249"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoDollar24;
