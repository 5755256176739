import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoGreenCard24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M4.852 5.24c-.287.042-.554.18-.802.413a1.515 1.515 0 0 0-.452.728c-.087.299-.087 10.94 0 11.239a1.62 1.62 0 0 0 1.237 1.139c.297.056 14.033.056 14.33 0a1.62 1.62 0 0 0 1.237-1.139c.087-.299.087-10.94 0-11.239a1.515 1.515 0 0 0-.452-.728 1.451 1.451 0 0 0-.838-.415c-.304-.044-13.959-.042-14.26.002M18.94 12v5.26H5.06l-.01-5.22c-.006-2.871-.002-5.243.009-5.27.015-.04 1.426-.048 6.95-.04l6.931.01V12M7.3 10.706a.692.692 0 0 0-.364.4.74.74 0 0 0 .359.931c.17.083.225.087 1.016.075l.835-.012.151-.115c.382-.292.437-.744.132-1.091-.208-.237-.29-.254-1.198-.252-.66.001-.833.013-.931.064m4.13.017a.848.848 0 0 0-.26.245c-.09.136-.11.211-.11.412 0 .282.095.46.333.624l.141.096 2.499.011c2.483.011 2.499.01 2.675-.075.537-.26.543-1.045.01-1.317-.149-.076-.244-.079-2.646-.077-2.405.001-2.497.004-2.642.081m-4.09 3.293c-.672.301-.577 1.267.141 1.42.277.06 3.761.06 4.038 0a.744.744 0 0 0 .273-1.342l-.165-.114-2.084-.009c-1.672-.007-2.107.002-2.203.045m6.64.019c-.293.136-.427.356-.426.693.002.21.046.318.201.486.222.24.248.244 1.433.259 1.203.015 1.454-.013 1.658-.184a.757.757 0 0 0-.054-1.195l-.165-.114-1.243-.01c-1.143-.009-1.257-.004-1.404.065"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoGreenCard24;
