import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoSquareFrame24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M5.494 4.444c-.354.102-.772.458-.946.806-.168.334-.193.643-.18 2.193.012 1.402.013 1.419.106 1.58a.752.752 0 0 0 1.276.049l.11-.166.02-1.503L5.9 5.9l1.503-.02 1.503-.02.166-.11a.846.846 0 0 0 .247-.268c.096-.187.107-.515.025-.674a1.066 1.066 0 0 0-.371-.363c-.108-.055-3.288-.057-3.479-.001m9.526.002a1.114 1.114 0 0 0-.364.362c-.082.159-.071.487.025.674a.846.846 0 0 0 .247.268l.166.11 1.503.02 1.503.02.02 1.503.02 1.503.11.166a.752.752 0 0 0 1.276-.049c.093-.161.094-.178.106-1.58.013-1.545-.013-1.86-.178-2.189-.13-.259-.449-.573-.734-.72l-.22-.114-1.7-.01c-1.203-.007-1.723.004-1.78.036M9.549 8.657a1.652 1.652 0 0 0-.916.937c-.088.221-.091.265-.105 2.155-.008 1.114.003 2.066.025 2.253.095.785.636 1.342 1.4 1.441.351.045 3.748.045 4.101-.001a1.617 1.617 0 0 0 1.253-.907c.159-.347.18-.703.165-2.786-.014-1.89-.017-1.934-.105-2.155a1.671 1.671 0 0 0-.948-.951c-.215-.08-.293-.083-2.431-.082l-2.208.001-.231.095M13.94 12v1.94h-3.88l-.01-1.9c-.006-1.045-.002-1.923.008-1.95.016-.04.427-.049 1.951-.04l1.931.01V12m-9.208 2.707a.92.92 0 0 0-.258.27c-.093.161-.094.178-.106 1.58-.013 1.507.013 1.853.161 2.163.12.251.47.607.721.732.334.168.643.193 2.193.18 1.402-.012 1.419-.013 1.58-.106a.752.752 0 0 0 .049-1.276l-.166-.11-1.513-.012-1.513-.012v-1.465c0-1.417-.003-1.472-.086-1.643a.752.752 0 0 0-1.062-.301m13.742.008a.715.715 0 0 0-.268.293c-.083.171-.086.226-.086 1.643v1.465l-1.513.012-1.513.012-.166.11a.752.752 0 0 0 .049 1.276c.161.093.178.094 1.58.106 1.55.013 1.859-.012 2.193-.18.251-.125.601-.481.721-.732.148-.31.174-.656.161-2.163-.012-1.402-.013-1.419-.106-1.58a.741.741 0 0 0-1.052-.262"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoSquareFrame24;
