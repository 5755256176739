import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoWarning16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M7.654 1.243a1.303 1.303 0 0 0-.731.54c-.245.373-5.735 9.777-5.797 9.931a1.422 1.422 0 0 0-.061.719c.102.488.508.889 1.012.995.264.056 11.582.056 11.846 0 .504-.106.91-.507 1.012-.995a1.415 1.415 0 0 0-.062-.72c-.062-.154-5.551-9.556-5.798-9.932a1.333 1.333 0 0 0-.785-.553 1.624 1.624 0 0 0-.636.015m3.218 6.066c1.559 2.661 2.834 4.853 2.834 4.871.001.027-1.159.033-5.709.033-5.509 0-5.709-.001-5.697-.048.01-.038 5.475-9.396 5.638-9.653.021-.034.052-.057.069-.052.017.006 1.306 2.188 2.865 4.849m-3.125-1.87a.598.598 0 0 0-.358.466c-.039.214-.039 2.377 0 2.589.034.181.12.316.276.428.099.072.127.078.335.078.208 0 .236-.006.335-.078a.644.644 0 0 0 .276-.428c.039-.212.039-2.375 0-2.589A.605.605 0 0 0 8 5.388c-.09 0-.188.02-.253.051m.037 4.304a.812.812 0 0 0-.556.611.8.8 0 0 0 .412.855c.144.078.566.084.702.009.55-.3.601-1.008.098-1.366a.76.76 0 0 0-.656-.109"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoWarning16;
