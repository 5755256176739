import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoUser24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M11.4 3.585c-1.708.327-2.93 1.541-3.219 3.198-.284 1.626.54 3.279 2.019 4.053.209.109.542.248.74.308.33.101.417.11 1.04.113.801.003 1.059-.053 1.726-.377 1.521-.739 2.403-2.442 2.115-4.085C15.554 5.268 14.515 4.12 13 3.678c-.236-.068-.428-.09-.9-.099a7.711 7.711 0 0 0-.7.006M12.5 5.1c.858.18 1.599.899 1.799 1.744A2.376 2.376 0 0 1 13.66 9.1a2.329 2.329 0 0 1-3.321 0c-.476-.476-.697-1.015-.698-1.702-.002-.659.21-1.169.679-1.641A2.316 2.316 0 0 1 12.5 5.1m-1.088 7.225a6.807 6.807 0 0 0-5.668 3.809c-.203.415-.507 1.296-.586 1.697-.16.818.361 1.587 1.194 1.764.3.064 10.996.064 11.296 0 .652-.138 1.159-.687 1.218-1.318.019-.206.002-.342-.081-.663-.448-1.736-1.341-3.064-2.73-4.06-1.296-.93-2.875-1.348-4.643-1.229m1.708 1.572a5.275 5.275 0 0 1 3.802 2.959c.183.388.356.861.416 1.134l.028.13H6.634l.027-.13c.104-.504.462-1.324.8-1.83.644-.967 1.683-1.756 2.759-2.096a7.848 7.848 0 0 1 1.12-.239c.266-.04 1.452.008 1.78.072"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoUser24;
