import styled from 'styled-components';

const FormInputGroup = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  isolation: isolate;
`;

export default FormInputGroup;
