import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoShield24 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M8.54 4.654c-1.738.579-3.239 1.094-3.336 1.143-.408.21-.731.679-.808 1.173-.06.385-.024 5.597.041 5.989a7.235 7.235 0 0 0 1.35 3.212c.377.51 1.286 1.416 1.893 1.884.887.685 2.383 1.615 3.722 2.314.546.285.65.285 1.196 0 1.385-.723 2.968-1.714 3.822-2.394 1.95-1.552 2.972-3.286 3.177-5.392.055-.556.057-5.313.004-5.641-.08-.483-.41-.945-.826-1.153-.107-.054-1.609-.569-3.336-1.144-2.987-.994-3.157-1.045-3.44-1.045-.284 0-.457.053-3.459 1.054m6.552 1.464 3.033 1.01-.016 2.806c-.014 2.418-.026 2.847-.083 3.106-.384 1.75-1.456 3.104-3.557 4.495-.596.395-1.471.915-2.099 1.249l-.37.196-.37-.196a29.253 29.253 0 0 1-2.106-1.253c-2.259-1.496-3.393-3.041-3.601-4.902-.026-.235-.043-1.386-.043-2.944V7.127l3.05-1.02c1.677-.562 3.068-1.016 3.09-1.01.022.006 1.405.466 3.072 1.021m-.912 3.06a1.71 1.71 0 0 1-.16.041c-.088.02-.489.394-1.49 1.393l-1.371 1.366-.509-.505c-.281-.278-.568-.534-.638-.569-.427-.213-.987.107-1.042.596-.039.345.034.449.96 1.368.875.868.963.933 1.27.931.249-.002.43-.16 2.107-1.845 1.656-1.663 1.687-1.697 1.714-1.884.015-.104.016-.253.002-.331-.03-.158-.219-.394-.385-.48-.114-.059-.388-.108-.458-.081"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoShield24;
