import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoCheckCircle40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M18.467 3.408c-2.622.231-5.329 1.176-7.567 2.641-1.059.693-1.735 1.247-2.702 2.215-3.277 3.28-4.999 7.643-4.843 12.269.119 3.531 1.231 6.681 3.367 9.534.662.883 2.119 2.374 2.945 3.011 2.403 1.857 4.978 2.961 8 3.433 1.077.168 3.589.168 4.666 0 3.731-.582 6.84-2.144 9.437-4.741 2.597-2.598 4.163-5.714 4.74-9.437.167-1.076.167-3.59 0-4.666-.468-3.016-1.578-5.603-3.432-8-.562-.726-2.014-2.177-2.745-2.743-3.444-2.666-7.589-3.895-11.866-3.516m2.757 3.327a13.204 13.204 0 0 1 8.033 3.691 13.3 13.3 0 0 1 2.715 15.406 13.036 13.036 0 0 1-2.812 3.836 12.513 12.513 0 0 1-3.374 2.333 13.246 13.246 0 0 1-11.572 0c-1.752-.846-3.194-1.976-4.469-3.501-3.331-3.982-4.012-9.663-1.718-14.333 2.243-4.566 6.751-7.419 11.84-7.491.348-.005.959.022 1.357.059m3.137 7.56a2.074 2.074 0 0 0-.434.29c-.126.111-1.654 1.944-3.395 4.073l-3.165 3.872-1.134-1.125c-.623-.619-1.238-1.18-1.366-1.247-.341-.177-1.082-.166-1.434.021-.588.314-.913.845-.913 1.493 0 .668.095.792 2.189 2.869 1.602 1.589 1.977 1.928 2.242 2.026.585.218 1.303.037 1.761-.444.472-.496 7.642-9.309 7.775-9.556.2-.374.199-1.093-.002-1.473-.167-.317-.547-.671-.861-.802-.278-.116-.993-.115-1.263.003"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoCheckCircle40;
