import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoRepeat40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M10.8 3.392c-.406.133-.7.398-3.013 2.72C4.934 8.976 4.967 8.93 4.967 10c0 1.07-.033 1.024 2.82 3.889 1.389 1.393 2.558 2.513 2.705 2.589 1.092.565 2.403-.241 2.403-1.478 0-.642-.152-.87-1.45-2.183l-1.137-1.15h21.354l.019 3.588.019 3.589.202.328a1.664 1.664 0 0 0 2.863 0l.202-.328.018-3.753c.021-4.073.009-4.247-.349-4.953-.226-.445-.808-1.056-1.274-1.336-.816-.492-.005-.461-12.158-.465l-10.896-.004 1.137-1.15c1.298-1.313 1.45-1.541 1.45-2.183 0-.656-.323-1.175-.923-1.481-.287-.146-.908-.214-1.172-.127M6.158 20.095a1.724 1.724 0 0 0-.945.765l-.18.307-.018 3.762c-.013 2.475.005 3.88.052 4.104.18.869.935 1.863 1.715 2.258.772.391.214.374 12.047.375l10.863.001-1.137 1.15c-1.298 1.313-1.45 1.541-1.45 2.183 0 1.237 1.311 2.043 2.403 1.478.319-.165 5.098-4.955 5.256-5.268a2.613 2.613 0 0 0 0-2.42c-.157-.311-4.935-5.1-5.264-5.277-.371-.198-1.093-.199-1.468-.001-.61.322-.927.832-.927 1.488 0 .642.152.87 1.45 2.183l1.137 1.15H8.338l-.019-3.583-.019-3.583-.18-.307a1.674 1.674 0 0 0-1.962-.765"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoRepeat40;
