'use client';

import Addon from './components/form-addon';
import Checkbox from './components/form-checkbox';
import Errors from './components/form-errors';
import Field from './components/form-field';
import Context from './components/form-field-context';
import Group from './components/form-group';
import Hint from './components/form-hint';
import Input from './components/form-input';
import Label from './components/form-label';
import Radio from './components/form-radio';
import Select from './components/form-select';
import TextArea from './components/form-textarea';

const Form = {
  Field,
  Label,
  Group,
  Addon,
  Errors,
  Context,
  Input,
  Select,
  Radio,
  Checkbox,
  Hint,
  TextArea,
};

export default Form;
