import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoForbid40 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M18.467 3.408c-2.622.231-5.329 1.176-7.567 2.641-1.059.693-1.735 1.247-2.702 2.215-3.277 3.28-4.999 7.643-4.843 12.269.119 3.531 1.231 6.681 3.367 9.534.662.883 2.119 2.374 2.945 3.011 2.403 1.857 4.978 2.961 8 3.433 1.077.168 3.589.168 4.666 0 3.731-.582 6.84-2.144 9.437-4.741 2.597-2.598 4.163-5.714 4.74-9.437.167-1.076.167-3.59 0-4.666-.468-3.016-1.578-5.603-3.432-8-.562-.726-2.014-2.177-2.745-2.743-3.444-2.666-7.589-3.895-11.866-3.516m2.757 3.327a13.204 13.204 0 0 1 8.033 3.691 13.3 13.3 0 0 1 2.715 15.406 13.036 13.036 0 0 1-2.812 3.836 12.513 12.513 0 0 1-3.374 2.333 13.246 13.246 0 0 1-11.572 0c-1.752-.846-3.194-1.976-4.469-3.501-3.331-3.982-4.012-9.663-1.718-14.333 2.243-4.566 6.751-7.419 11.84-7.491.348-.005.959.022 1.357.059m-6.733 6.693c-.381.12-.731.403-.944.766-.153.26-.18.381-.179.806.001.354.037.568.124.733.067.129 1.026 1.141 2.131 2.25L17.63 20l-2.007 2.017c-1.105 1.109-2.059 2.117-2.121 2.241-.382.757-.076 1.744.679 2.188.274.161.389.187.819.186.354-.001.568-.037.733-.124.129-.067 1.141-1.026 2.25-2.131L20 22.37l2.017 2.007c1.109 1.105 2.117 2.059 2.241 2.121.757.382 1.744.076 2.188-.679.161-.274.187-.389.186-.819-.001-.354-.037-.568-.124-.733-.067-.129-1.026-1.141-2.131-2.25L22.37 20l2.007-2.017c1.105-1.109 2.064-2.121 2.131-2.25.087-.165.123-.379.124-.733.001-.425-.026-.546-.179-.806a1.694 1.694 0 0 0-.97-.769c-.393-.118-.578-.115-1.021.015-.341.102-.479.224-2.412 2.147L20 17.627l-2.05-2.04c-1.933-1.923-2.071-2.045-2.412-2.147-.427-.126-.672-.129-1.047-.012"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoForbid40;
