import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoLang16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M5.202 1.897a.608.608 0 0 0-.482.501 3.206 3.206 0 0 0-.027.431v.288l-1.18.008c-1.132.008-1.185.011-1.3.064-.277.129-.426.336-.446.62-.017.231.023.37.152.533.166.21.329.276.723.292.297.012.32.017.332.07l.09.403c.24 1.069.66 2.039 1.175 2.714l.104.137-.085.057c-.323.216-.918.472-1.486.639-.41.12-.535.19-.629.349a.624.624 0 0 0 .022.696.634.634 0 0 0 .483.253c.188.001.848-.194 1.339-.397a8.304 8.304 0 0 0 1.009-.526c.126-.082.239-.149.25-.148.012 0 .051.024.087.054.173.14.633.424.947.583.401.204.87.386 1.351.523.318.091.348.095.488.066a.613.613 0 0 0 .498-.596c0-.309-.178-.526-.516-.628-.716-.217-1.258-.448-1.693-.724l-.218-.138.181-.231c.585-.744 1.002-1.681 1.255-2.817l.071-.32.325-.016c.4-.021.561-.086.726-.295.128-.163.168-.302.152-.533-.02-.284-.169-.491-.447-.62-.115-.053-.168-.056-1.296-.064L5.98 3.117l-.011-.379c-.01-.326-.02-.397-.073-.505-.126-.261-.394-.391-.694-.336m1.182 2.905c-.209.895-.561 1.666-1.047 2.288l-.049.064-.149-.224c-.41-.616-.682-1.299-.884-2.217l-.016-.073h2.183l-.038.162m4.753 1.149a1.186 1.186 0 0 0-.712.367 1.434 1.434 0 0 0-.237.309c-.102.219-2.289 6.023-2.309 6.127a.593.593 0 0 0 .028.25c.089.309.41.509.706.441a.65.65 0 0 0 .412-.283c.034-.06.148-.337.253-.615l.192-.507H13.197l.191.507c.105.278.219.555.253.615a.65.65 0 0 0 .413.283c.295.068.616-.132.706-.441a.574.574 0 0 0 .027-.25c-.019-.104-2.207-5.908-2.309-6.127-.145-.311-.577-.629-.905-.667l-.2-.024a1.42 1.42 0 0 0-.236.015m.89 2.989c.364.964.67 1.776.679 1.806.018.054.012.054-1.371.054-.764 0-1.388-.006-1.388-.013 0-.008.3-.809.667-1.78l.68-1.803c.007-.019.026-.031.042-.025.016.005.327.798.691 1.761"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoLang16;
